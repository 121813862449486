import React from "react";

import DocumentResults from "./DocumentResults";
import { SharepointTopResults } from "./SharepointTopResults";

import { QueryResult, QueryResultItemList } from "./../kendraTypes";
import "../search.scss";

interface ResultsPanelProps {
  results: QueryResult;
  topResults: QueryResultItemList;
  docResults: QueryResultItemList;
  dataReady: boolean;
  currentPageNumber: number;
}

export default class ResultsPanel extends React.Component<ResultsPanelProps, {}> {
  render() {
    const { topResults, docResults, dataReady } = this.props;

    if (dataReady) {
      return (
        <div className="results-section">
          <div className="results-number">
            <span>&nbsp;</span>
          </div>

          <SharepointTopResults results={topResults} hasDocumentResults={docResults.length > 0} />
          <DocumentResults results={docResults} />
        </div>
      );
    } else {
      return undefined;
    }
  }
}
