import React from 'react';
import { Container } from "react-bootstrap";
import './App.css';

import Search from './search/Search.tsx';

function App() {
  return (
    <div className="App">
      <Container className="pl-0 pr-0">
        <Search />
      </Container>
    </div>
  );
}

export default App;