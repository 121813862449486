import React from "react";

import axios from 'axios';
import queryString from 'query-string';

import { QueryResultType } from "./constants";
import { isNullOrUndefined, hasPreloadParams } from "./utils";
import { Spinner } from "react-bootstrap";
import {
  QueryResult,
  QueryResultItem,
  QueryResultItemList
} from "./kendraTypes";

import {
  SharepointQueryResultItem,
  SharepointQueryResultItemImpl
} from "./sharepointTypes";

import SearchBar from "./searchBar/SearchBar";
import ResultPanel from "./resultsPanel/ResultPanel";
import Pagination from "./pagination/Pagination";

import "bootstrap/dist/css/bootstrap.min.css";
import "./search.scss";

interface SearchProps { }

interface SearchState {
  dataReady: boolean;
  shouldPreloadSuggestedDocs: boolean;
  searchResults: QueryResult;
  topResults: QueryResultItemList;
  docResults: QueryResultItemList;
  currentPageNumber: number;
  queryText: string;
  paramDict: queryString.ParsedQuery<string>;
  authToken: string;
  isError: boolean;
  errCodes: Array<String>;
}

export default class Search extends React.Component<SearchProps, SearchState> {

  constructor(props: SearchProps) {
    super(props);

    let paramDict:queryString.ParsedQuery<string> = {};
    let queryParamText:string = "";
    let authTokenText:string = "";
    if (window.location.search) {
      // TODO possibly look at better ways to retrieve query params
      paramDict = queryString.parse(window.location.search);
    }

    if (paramDict) {
      if (paramDict.queryText) {
        queryParamText = paramDict.queryText as string;
      }
      if (paramDict.authToken) {
        authTokenText = paramDict.authToken as string;
      }
    }

    this.state = {
      dataReady: false,
      shouldPreloadSuggestedDocs: hasPreloadParams(Object.keys(paramDict as any)),
      searchResults: {},
      topResults: [],
      docResults: [],
      currentPageNumber: 1,
      queryText: queryParamText,
      authToken: authTokenText,
      paramDict: paramDict,
      isError: false,
      errCodes: []
    };
  }

  async componentDidMount() {
    await this.getResults(this.state.queryText);
  }

  private async querySharepoint(authToken: string) {
    let sharepointApiUrlStr: string = process.env.REACT_APP_KBMGMT_SHAREPOINT_URL || 'https://trax-kbmgmt.firsttrax-dev.magellanrx.com/api/kbmgmt/sharepoint';

    let sharepointResults: QueryResultItemList = [];
    let sharepointFilterArray: Object[] = [];

    Object.keys(this.state.paramDict).forEach(item => {
      if (item !== 'queryText' && item !== 'authToken') {
        sharepointFilterArray.push({
          'Key': item,
          'Value': this.state.paramDict[item]
        });
      }
    });

    if (sharepointFilterArray.length > 0 && authToken) {
      const postData = { 'Filters': sharepointFilterArray };
      const headers = {
        headers: { 'Content-Type': 'application/json', 'Authorization': '' }
      };
      headers.headers.Authorization = `Bearer ${authToken}` as string;
      console.log(`POSTING to [${sharepointApiUrlStr}] with postData:`, postData);
      const rawSharepointResults = await axios.post(sharepointApiUrlStr, postData, headers).catch(err => {
        console.log('error while POSTing to sharepoint:', JSON.stringify(err));
        const errCodes = this.state.errCodes.concat('OCU-KM1001');
        this.setState({ errCodes: errCodes });
        this.setState({isError: true});
        return {data: []};
      });
      const tempSharepointResults = rawSharepointResults.data;
      console.log('tempSharepointResults:', tempSharepointResults);

      if (tempSharepointResults.length > 0) {
        sharepointResults = tempSharepointResults.map((e: SharepointQueryResultItem, i: number) => {
          return (new SharepointQueryResultItemImpl(e.title, e.url)).toQueryResultItem();
        });
      }
      console.log('sharepointResults:', sharepointResults);
    }
    // if there is no valid auth token or no filters, we return []
    return sharepointResults;
  }

  private async queryKendra(queryText: string, authToken: string) {
    let kendraResults: QueryResultItemList = [];
    // this is a temporary hack to support both local dev and deployment
    // this variable should get replaced by the buildspec.yml if being deployed
    let kendraApiUrlStr: string = process.env.REACT_APP_KBMGMT_API_URL || 'https://trax-kbmgmt.firsttrax-dev.magellanrx.com/api/kbmgmt/search';
    let kendraFilterArray: Object[] = [];

    Object.keys(this.state.paramDict).forEach(item => {
      if (item === 'ClientName') {
        kendraFilterArray.push({
          'Key': item,
          'Value': this.state.paramDict[item]
        });
      }
    });

    const postData = { 'QueryText': queryText, 'Filters': kendraFilterArray };
    let headers = {
      headers: { 'Content-Type': 'application/json', 'Authorization': '' },
    };
    if (authToken) {
      headers.headers.Authorization = `Bearer ${authToken}` as string;
      console.log(`POSTING to [${kendraApiUrlStr}] with postData:`, postData, ', headers:', headers);
      let rawKendraResults:any = {};
      rawKendraResults = await axios.post(kendraApiUrlStr, postData, headers).catch(err => {
        console.log('error while POSTing to kendra:', JSON.stringify(err));
        const errCodes = this.state.errCodes.concat('OCU-KM1002');
        this.setState({ errCodes: errCodes, isError: true });
        return {data: []};
      });
      kendraResults = rawKendraResults.data;
      console.log('kendraResults:', kendraResults);
    }
    else {
      console.log('no auth token found; terminating searches and displaying error');
      // changing errcode array update technique in this class to match https://stackoverflow.com/questions/37435334/correct-way-to-push-into-state-array
      const errCodes = this.state.errCodes.concat('OCU-KM1000');
      this.setState({ errCodes: errCodes, isError: true });
    }
    return kendraResults;
  }

  getResults = async (queryText: string, pageNumber: number = 1) => {
    this.setState({ dataReady: false, isError: false, errCodes: [] });

    let sharepointResults: QueryResultItemList = [];
    if (this.state.shouldPreloadSuggestedDocs) {
      sharepointResults = await this.querySharepoint(this.state.authToken);
    }

    let kendraResults: QueryResultItemList = [];
    if (queryText) {
      kendraResults = await this.queryKendra(queryText, this.state.authToken);
    }

    const newState: any = {
      currentPageNumber: pageNumber,
      queryText: queryText,
      dataReady: true
    };
    if (this.state.shouldPreloadSuggestedDocs) {
      newState.topResults = sharepointResults;
      newState.shouldPreloadSuggestedDocs = false;
    }
    const tempDocumentResults: QueryResultItemList = [];
    if (kendraResults.length > 0) {
      kendraResults.forEach((result: QueryResultItem) => {
        switch (result.Type) {
          case QueryResultType.Document:
            tempDocumentResults.push(result);
            break;
          default:
            break;
        }
      });
    }
    newState.searchResults = kendraResults;
    newState.docResults = tempDocumentResults;

    this.setState(newState);
  };

  render() {
    var qpt:string = this.state.queryText || "";
    console.log("error codes: " + this.state.errCodes.join(', '));

    return (
      <div>
        <SearchBar onSubmit={this.getResults} queryParamText={qpt} />
        {this.state.isError && (
          <div>
            <div>&nbsp;</div>
            <div className="alert alert-danger">
              <h4 className="alert-heading">Something went wrong</h4>
              {this.state.errCodes.join(', ') == 'OCU-KM1001' || this.state.errCodes.join(', ') == 'OCU-KM1002'
                ? <p>Connectivity Error: MRx Source access requires both an active VPN & Okta session. Please confirm both are active. If both are active & the issue persists, please submit a Rita ticket.</p>
                : <p>Please try again.  If the issue persists, please contact the helpdesk and provide the following error codes: {this.state.errCodes.join(', ')}</p>
              }
            </div>
          </div>
        )}
        {this.state.dataReady &&
          !isNullOrUndefined(this.state.searchResults) && (
            <div>
              <ResultPanel
                results={this.state.searchResults}
                topResults={this.state.topResults}
                docResults={this.state.docResults}
                dataReady={this.state.dataReady}
                currentPageNumber={this.state.currentPageNumber}
              />
              <Pagination
                queryText={this.state.queryText}
                currentPageNumber={this.state.currentPageNumber}
                onSubmit={this.getResults}
                results={this.state.searchResults}
              />
            </div>
          )}

        {!this.state.dataReady && (
          <div className="results-section center-align">
            <Spinner animation="border" variant="primary" />
          </div>
        )}
        {this.state.dataReady &&
          this.state.searchResults.TotalNumberOfResults === 0 && (
            <div className="results-section empty-results">
              No matching results to your query.
            </div>
          )}
      </div>
    );
  }
}