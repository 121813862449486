import React from "react";
import _ from "lodash";

import { isNullOrUndefined } from "../utils";
import {
  QueryResultItem,
  QueryResultItemList
} from "../kendraTypes";

import ResultTitle from "./components/ResultTitle";

import "../search.scss";

const RESULTS_BLOCK_TITLE = "Suggested articles";
export const TOP_N_RESULTS = 3;

interface TopResultsProps {
  results: QueryResultItemList;
  hasDocumentResults: boolean;
}

interface TopResultsState {
  totalResults: number;
  isCollapsed: boolean;
  hasDocumentResults: boolean;
}

export class SharepointTopResults extends React.Component<TopResultsProps, TopResultsState> {

  constructor(props: TopResultsProps) {
    super(props);
    this.state = {
      totalResults: props.results.length,
      isCollapsed: true,
      hasDocumentResults: props.hasDocumentResults
    };
    this.toggleCollapsed = this.toggleCollapsed.bind(this);
  }

  toggleCollapsed() {
    this.setState({isCollapsed: !this.state.isCollapsed});
  }

  private getMoreButton() {
    const tempTopNResults = this.state.hasDocumentResults ? 0 : TOP_N_RESULTS;
    let buttonText;

    if (this.state.isCollapsed && this.state.hasDocumentResults) {
      buttonText = `Show suggestions (${this.state.totalResults})`;
    } else if (this.state.isCollapsed && !this.state.hasDocumentResults) {
      buttonText = `Show ${Math.max(this.state.totalResults - tempTopNResults, 0)} more ...`;
    } else {
      buttonText = 'Collapse';
    }

    if (this.state.totalResults > tempTopNResults) {
      return (
        <button className="btn btn-light" type="button" onClick={this.toggleCollapsed}>
          {buttonText}
        </button>
      );
    } else {
      return null;
    }
  }

  private renderResults = (result: QueryResultItem, idx: number) => {
    if (!isNullOrUndefined(result)) {
      let attributes = Object();

      return (
        <React.Fragment key={result.Id}>
          <div className="container-body py-2">
            <ResultTitle queryResultItem={result} attributes={attributes} />
          </div>
        </React.Fragment>
      );
    } else {
      return null;
    }
  };

  private renderResultItem = (result: QueryResultItem, idx: number) => {
    const shouldCollapseItem: boolean = ((idx >= TOP_N_RESULTS) || this.state.hasDocumentResults) && this.state.isCollapsed;
    return (
      <div className={"result-item" + (shouldCollapseItem ? " collapse" : "")} key={idx}>
        {result}
      </div>
    );
  };

  render() {
    const { results } = this.props;
    const resultsToShow = results.map(this.renderResults);

    if (isNullOrUndefined(results) || results.length === 0) {
      return null;
    }

    const moreButton = this.getMoreButton();

    return (
      <div className="result-container card">
        <div className="card-title">{RESULTS_BLOCK_TITLE}</div>
        <div className="container-divider" />
        { resultsToShow.map((result, i) => this.renderResultItem(result as QueryResultItem, i)) }
        {moreButton}
      </div>
    );
  }
}
