import { QueryResultItem } from "./kendraTypes";

export interface SharepointQueryResult {
  ResultItems?: SharepointQueryResultItemList;
}

export interface SharepointQueryResultItem {
  title: string;
  url: string;
  toQueryResultItem(): QueryResultItem;
}

export type SharepointQueryResultItemList = SharepointQueryResultItem[];

export class SharepointQueryResultItemImpl implements SharepointQueryResultItem {
  title: string;
  url: string;
  toQueryResultItem() {
    return {
      DocumentTitle: {
        Text: this.title,
        Highlights: []
      },
      DocumentURI: this.url
    };
  }
  constructor(title: string, url: string) {
    this.title = title;
    this.url = url;
  }
}